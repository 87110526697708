import * as React from "react";

interface OpenTableProps {
  rid: string;
  type?: "button" | "standard";
  theme?: "tall" | "wide" | "standard";
  iframe?: boolean;
  newtab?: boolean;
  ot_source?:
    | "Restaurant website"
    | "Email"
    | "Facebook"
    | "Google"
    | "Instagram"
    | "Other";
  ot_campaign?: string;
  customClassName?: string;
}

const staticProps = {
  color: "1",
  dark: true,
  domain: "comau",
  lang: "eu-AU",
};

const BASE_URL = "https://www.opentable.com.au/widget/reservation/loader";

function generateScriptTag(props: OpenTableProps) {
  const script = document.createElement("script");
  const searchProps = Object.fromEntries(
    [...Object.entries(props), ...Object.entries(staticProps)].map(([k, v]) => [
      k,
      v.toString(),
    ])
  );
  const params = new URLSearchParams(searchProps);

  script.src = BASE_URL + "?" + params.toString();
  script.async = true;

  return script;
}

export default function OpenTable(props: OpenTableProps) {
  const scriptRef = React.useRef<HTMLScriptElement>();
  const el = React.useRef<HTMLDivElement>(null);

  React.useEffect(function () {
    scriptRef.current = generateScriptTag(props);
    document.getElementById("open-table")?.appendChild(scriptRef.current);
  }, []);

  React.useEffect(
    function () {
      if (scriptRef && scriptRef.current) {
        const oldParams = new URL(scriptRef.current.src).search;
        const searchProps = Object.fromEntries(
          Object.entries(props).map(([k, v]) => [k, v.toString()])
        );
        const newParams = "?" + new URLSearchParams(searchProps).toString();

        if (oldParams !== newParams) {
          Array.from(el.current?.children ?? []).forEach(function (element) {
            return element.remove();
          });
          scriptRef.current = generateScriptTag(props);
          el.current?.appendChild(scriptRef.current);
        }
      }
    },
    [props, scriptRef]
  );

  return <div ref={el}></div>;
}
