import * as React from "react";
import Layout from "../components/layout";
import Container from "../components/container";
import TopSection from "../components/top-section";
import MidSection from "../components/mid-section";
import UnderlayTitle from "../components/underlay-title";
import useBookingsPage from "../hooks/use-bookings-page";
import OpenTable from "../components/open-table";
import OpeningHours from "../components/opening_hours";

export default function BookPage() {
  const sections = {
    reservations: {
      label: "Reservations",
      slug: "reservations",
    },
    opening_hours: {
      label: "Opening Hours",
      slug: "opening-hours",
    },
    tsandcs: {
      label: "Reservations T+C's",
      slug: "tsandcs",
    },
    top: {
      label: "Back",
      slug: "top",
    },
  };

  const { bookings, termsAndConditions } = useBookingsPage();

  return (
    <Layout title={"Book"}>
      <TopSection
        pageTitle={"Book"}
        sections={[
          sections.reservations,
          sections.opening_hours,
          sections.tsandcs,
        ]}
      />
      <MidSection
        className={"bg-white"}
        slug={sections.reservations.slug}
        prevSection={sections.top}
      >
        <Container className={"justify-center items-center"}>
          <div
            className={
              "flex px-4 w-full lg:w-1/2 lg:px-4 justify-center items-center relative flex-col"
            }
          >
            <UnderlayTitle overlay={true} className={"z-0 text-primary"}>
              Book
            </UnderlayTitle>
            <div
              className={"prose relative text-xl py-8"}
              dangerouslySetInnerHTML={{ __html: bookings.content }}
            />
            <OpenTable
              rid={"170426"}
              type={"button"}
              theme={"standard"}
              iframe={true}
              newtab={false}
              ot_source={"Restaurant website"}
            />
          </div>
        </Container>
      </MidSection>
      <MidSection className={"bg-primary"} slug={sections.opening_hours.slug}>
        <Container className={"justify-center"}>
          <div className={"flex px-4 lg:px-4 flex-col items-center"}>
            <UnderlayTitle
              overlay={true}
              size={"medium"}
              className={"z-0 leading-none text-center text-white"}
            >
              Opening
              <br />
              Hours
            </UnderlayTitle>
            <OpeningHours />
          </div>
        </Container>
      </MidSection>
      <MidSection className={"bg-white"} slug={sections.tsandcs.slug}>
        <Container className={"justify-center"}>
          <div className={"flex px-4 lg:px-4 flex-col items-center"}>
            <UnderlayTitle
              overlay={true}
              size={"medium"}
              className={"z-0 leading-none text-center text-primary"}
            >
              Reservation <br />
              {"T+C's"}
            </UnderlayTitle>
            <div
              className={"prose lg:w-2/3 relative text-xl"}
              dangerouslySetInnerHTML={{ __html: termsAndConditions.content }}
            />
          </div>
        </Container>
      </MidSection>
    </Layout>
  );
}
