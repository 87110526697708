import { useStaticQuery, graphql } from "gatsby";

export interface BookingsPage {
  reservations: {
    bookingFormUrl: string;
  };
  bookings: {
    content: string;
  };
  groupBookings: {
    content: string;
  };
  termsAndConditions: {
    content: string;
  };
}

export default function useBookingsPage(): BookingsPage {
  const { pagesYaml } = useStaticQuery(
    graphql`
      query {
        pagesYaml(
          parent: {
            internal: {
              description: { regex: "/.*site/content/pages/bookings.*/" }
            }
          }
        ) {
          reservations {
            bookingFormUrl: booking_form_url
          }
          bookings {
            content
          }
          groupBookings: group_bookings {
            content
          }
          termsAndConditions: terms_and_conditions {
            content
          }
        }
      }
    `
  );
  return pagesYaml;
}
